import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Information = makeShortcode("Information");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rate-limits"
    }}>{`Rate limits`}</h1>
    <p>{`LiveSession platform features and APIs rely on rate limits to help provide a predictably and stability of use.`}</p>
    <p>{`Below, you can find the list of methods which call rate limited API endpoints:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Method`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Record API endpoint`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Limitation`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Time scope`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Max calls`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/javascript-api/methods/#identify"
            }}><inlineCode parentName="a">{`identify`}</inlineCode></a>{` / `}<a parentName="td" {...{
              "href": "/javascript-api/methods/#setcustomparams"
            }}><inlineCode parentName="a">{`setCustomParams`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/visitors/update_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`per session`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`per second`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "/javascript-api/methods/#identify"
            }}><inlineCode parentName="a">{`identify`}</inlineCode></a>{` / `}<a parentName="td" {...{
              "href": "/javascript-api/methods/#setcustomparams"
            }}><inlineCode parentName="a">{`setCustomParams`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`/visitors/update_data`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`per session`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`per minute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
      </tbody>
    </table>
    <Information color="info" mdxType="Information">
  <b>identify</b> and <b>setCustomParams</b> both share the same limits.
    </Information>
    <Information color="warning" mdxType="Information">If limits are exceeded, new values passed by these methods won't be set.</Information>
    <h3 {...{
      "id": "api"
    }}>{`API`}</h3>
    <p>{`All JavaScript API methods use Record Session API (rs.livesession.io).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      